import React, {useState} from 'react';

import RegisterPageLayout from '../../../../components/layout/RegisterPageLayout';
import Input from '../../../../components/input/Input';
import {Departamento} from "../../../../types";

import Select from "../../../../components/select/Select";
import departamentoApi from "../../../../services/departamentoApi";
export default function FormDepartament() {

    const [state, setState] = useState<Departamento>({
        nome: '',
        status: true,
    });


    return(
        <RegisterPageLayout<Departamento>
            resourceName="Departamento"
            menuTitle="Cadastro de Departamento"
            finishPath="/departamento/lista"
            newPath="/departamento/cadastro"
            returnPath="/departamento/lista"
            loader={departamentoApi.one}
            destroyHandler={departamentoApi.destroy}
            createHandler={departamentoApi.create}
            updateHandler={departamentoApi.update}
            initialData={state}
            formContent={(form, handleChange, id, load) => {
                return(<>
                    {
                        load ? "" :
                            <div className="border-b grid grid-cols-12 gap-5 pb-7">
                                <Input
                                    label="Nome *"
                                    className="col-span-6"
                                    value={form.nome}
                                    setValue={v => handleChange(v, 'nome')}
                                />
                                <Select
                                    label="Status"
                                    className="col-span-3"
                                    value={form.status}
                                    setValue={v => handleChange(v, 'status')}
                                    items={[
                                        {label: 'Ativo', key: true},
                                        {label: 'Inativo', key: false},
                                    ]}
                                />
                            </div>
                    }
                </>)
            }}
        />
    );
}