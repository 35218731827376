import { QueryParams, Cliente } from '../types';
import {getAll, create, update, getOne, deleteOne, listAll} from './api';

const endpoint = 'clientes';

export default {
    all: async (params?: QueryParams) => getAll<Cliente>({ endpoint, params }),
    listAll: async () => listAll<Cliente>({ endpoint }),
    one: async (id: string) => getOne<Cliente>({ endpoint, id }),
    destroy: async (id: string) => deleteOne({ endpoint, id }),
    create: async (formData: Partial<Cliente>) => create<Cliente>({ endpoint, formData }),
    update: async (id: string, formData: Partial<Cliente>) => update<Cliente>({ endpoint, formData, id }),
};
