import api from './api';
//const endpoint = 'dashboard';

export default {
    async dashboardData(){
        try {
            const { data } = await api.get('/dashboard/count');
            return { data, isError: false };
        } catch (err: any) {
            return {
                data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
                isError: true
            };
        }
    },

    async dashboardClient(idCliente: any){
        try {
            const { data } = await api.get(`/dashboard/clientes/${idCliente}`);
            return { data, isError: false };
        } catch (err: any) {
            console.log(err);
            return {
                data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
                isError: true
            };
        }
    }
};
