import React, {useState} from 'react';

import RegisterPageLayout from '../../../../components/layout/RegisterPageLayout';
import Input from '../../../../components/input/Input';
import {Secao} from "../../../../types";

import Select from "../../../../components/select/Select";
import sectionApi from "../../../../services/sectionApi";
export default function FormSection() {

    const [state, setState] = useState<Secao>({
        nome: '',
        status: true,
    });


    return(
        <RegisterPageLayout<Secao>
            resourceName="Seção"
            menuTitle="Cadastro de Seção"
            finishPath="/secao/lista"
            newPath="/secao/cadastro"
            returnPath="/secao/lista"
            loader={sectionApi.one}
            destroyHandler={sectionApi.destroy}
            createHandler={sectionApi.create}
            updateHandler={sectionApi.update}
            initialData={state}
            formContent={(form, handleChange, id, load) => {
                return(<>
                    {
                        load ? "" :
                            <div className="border-b grid grid-cols-12 gap-5 pb-7">
                                <Input
                                    label="Nome *"
                                    className="col-span-6"
                                    value={form.nome}
                                    setValue={v => handleChange(v, 'nome')}
                                />
                                <Select
                                    label="Status"
                                    className="col-span-3"
                                    value={form.status}
                                    setValue={v => handleChange(v, 'status')}
                                    items={[
                                        {label: 'Ativo', key: true},
                                        {label: 'Inativo', key: false},
                                    ]}
                                />
                            </div>
                    }
                </>)
            }}
        />
    );
}