import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import Form from '../../../components/form/Form';
import LogoSrc from '../../../assets/images/logo.png';
import Title from '../../../components/text/Title';
import Input from '../../../components/input/Input';
import ColoredButton from '../../../components/button/ColoredButton';
import Alert from '../../../helpers/Alert';
import adminApi from "../../../services/adminApi";

export default function UserResetSenha() {
    const params = useParams();
    const id = params.hash;
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [state, setState] = useState({
        hash: id,
        password: '',
        repetirPassword: ''
    });

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (state.password != state.repetirPassword) {
            setErrorMessage('Senhas São diferentes');
            return;
        }

        const {data, isError} = await adminApi.resetAlterPassword(state);
        if (isError) return Alert.error(data);
        Alert.success('Senha alterada com sucesso!');
        navigate('/');
    }

    const handleChange = (value: any, input: any) => {
        setState({...state, [input]: value});
    };

    useEffect(() => {
        document.title = 'Nova senha';
    }, []);

    return(
        <div className="flex items-center justify-center h-screen p-5">
            <Form
                className="bg-background shadow lg:shadow-none p-9 grid place-items-center mx-auto w-full max-w-[410px] rounded-tl-md rounded-bl-md"
                onSubmit={handleSubmit}
            >
                <img src={LogoSrc} className="max-w-[104px]" />

                <Title size="2xl" color="primary" className="mb-5 mt-8">Bem vindo</Title>
                <p className="text-md text-secondary">Informe os dados para alterar sua senha</p>

                {!!errorMessage?.length && <p className="mt-5 text-red-600">{errorMessage}</p>}

                <Input
                    label="Nova senha"
                    inputType='password'
                    value={state.password}
                    setValue={(v) => handleChange(v, 'password')}
                />

                <Input
                    className={'mt-4'}
                    label="Repetir Senha"
                    inputType='password'
                    value={state.repetirPassword}
                    setValue={(v) => handleChange(v, 'repetirPassword')}
                />

                <ColoredButton
                    className="mt-6 mb-4"
                    loading={isLoading}
                    type="submit"
                >
                    Alterar senha
                </ColoredButton>

            </Form>
        </div>
    )

}