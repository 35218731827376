import Str from '../../../helpers/Str';
import React from 'react';
import { BiPlus } from 'react-icons/bi';

export interface CreationInputProps {
  value?: string;
  className?: string;
  placeholder?: string;
  setValue?: (value: string) => void;
  onBtnClick?: () => void;
}

export default function CreationInput({
  className,
  placeholder,
  value,
  setValue,
  onBtnClick,
}: CreationInputProps) {
  return (
    <div className={Str.tw(
      'relative',
      className
    )}>
      <input 
        placeholder={placeholder} 
        className="bg-light rounded-md focus:bg-background relative w-full h-full px-3 lg:px-6 py-4 outline-none border border-border focus:border-primary transition"
        value={value}
        onChange={({ target }) => setValue && setValue(target.value)}
      />
      <button 
        className="absolute top-0 right-0 bottom-0 bg-primary rounded-md px-3 hover:bg-primary-700 active:bg-primary-600 transition"
        type="button"
        onClick={() => onBtnClick && onBtnClick()}
      >
        <BiPlus size={24} className="fill-white " />
      </button>
    </div>
  );
}
