import React, {useEffect, useState} from 'react';
import PageContainer from "../../../components/container/PageContainer";
import Title from "../../../components/text/Title";
import PageCard from "../../../components/card/PageCard";
import clientesApi from "../../../services/clientApi";
import Alert from "../../../helpers/Alert";
import dashboardApi from "../../../services/dashboardApi";
import {Cliente} from "../../../types";
import Select from "../../../components/select/Select";
import Button from "../../../components/button/Button";
import BaseTable from "../../../components/table/BaseTable";
import DashboardCard from "../../../components/card/DashboardCard";
import {FaClipboardCheck, FaUser} from "react-icons/fa";
import {BiStore} from "react-icons/bi";

export default function DashboardClient() {
    const [clientDataSelect, setClienteDataSelect] = useState([]);
    const [clientData, setClienteData] = useState<any>([]);
    const [storeData, setStoreData] = useState<any>([]);
    const [searchData, setSearchData] = useState<any>();
    const [searchLoad, setSearchLoad] = useState(false);
    const [quantityStore, setQuantityStore] = useState(0);
    const [quantityUsers, setQuantityUsers] = useState(0);

    async function loadData() {
        Alert.await(`Carregando dados...`);
        const {data, isError} = await clientesApi.listAll();
        if (isError) return alert(data.message || 'Falha ao carregar dados.');
        const clientselect = data.map((res: any) => ({label: res.razao_social, key: res.id}));
        setClienteDataSelect(clientselect as any);
        Alert.close();
    }

    useEffect(() => {
        loadData()
    }, []);

    const handleClick = async () => {
        setSearchLoad(true);
        if (!searchData){
            setSearchLoad(false);
            return Alert.error('É necessário selecionar o cliente');
        }
        const {data, isError} = await dashboardApi.dashboardClient(searchData);
        if (isError) return alert(data.message || 'Falha ao carregar dados.');
        setSearchLoad(false);
        setClienteData(data);
        setStoreData(data.lojas);
        if (data.lojas){
            setQuantityStore(data.lojas.length);
            let quantityUser = 0;
            data.lojas.map((lj: any) => {
                if (lj.usuarios){
                    quantityUser += lj.usuarios.length;
                }
            });
            setQuantityUsers(quantityUser);
        }
    }

    return (
        <PageContainer menuTitle="Dashboard Cliente">
            <PageCard
                header={
                    <div className="flex items-center justify-between">
                        <Title color="primary">Dashboard Cliente</Title>
                    </div>
                }
            >
                <div className={'flex'}>
                    <div className="grid grid-cols-12 gap-5 pb-7 w-[50%]">
                        <Select
                            label="Clientes"
                            className="col-span-6"
                            value={searchData}
                            setValue={v => setSearchData(v)}
                            items={clientDataSelect}
                        />
                        <Button
                            className="min-w-[147px]"
                            onClick={() => handleClick()}
                            loading={searchLoad}
                        >
                            Buscar dados
                        </Button>
                    </div>
                    {
                        clientData && clientData.id ?
                            <div className="w-[50%]">
                                <div className="items-center">
                                    <div className="grid gap-7 grid-cols-2">
                                        <DashboardCard
                                            title={'Lojas'}
                                            value={quantityStore.toString()}
                                            icon={
                                                <BiStore color={'#515151'} size={'25px'}/>
                                            }
                                        />
                                        <DashboardCard
                                            title={'Usuários'}
                                            value={quantityUsers.toString()}
                                            icon={
                                                <FaUser color={'#515151'} size={'25px'}/>
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            :
                            ''
                    }

                </div>
                {
                    clientData ?
                        <div>
                            <div className={'py-5'}>
                                <h1 className={'text-xl font-bold'}>{clientData.razao_social}</h1>
                            </div>

                            {
                                storeData && storeData.map((st: any) => {
                                    return(
                                        <>
                                            <div className={'py-4'}>
                                                <h1 className={'text-lg'}>
                                                    {'Loja: ' + st['razao_social']}
                                                </h1>
                                                <h1 className={'text-lg'}>
                                                    {'Responsável: ' + st['responsavel'] ? st['responsavel'] : ''}
                                                </h1>
                                                <h1 className={'text-lg'}>
                                                    Usuários: {st.usuarios.length}
                                                </h1>
                                            </div>
                                            <div className={'pb-5'}>
                                                {
                                                    st.usuarios.length ?
                                                    <table className="table-auto  w-[50%]">
                                                        <thead className="bg-light border-b">
                                                        <tr className={'border-t'}>
                                                            <th className={'text-start py-3 px-5'}>Nome</th>
                                                            <th className={'text-start py-3 px-5'}>Email</th>
                                                            <th className={'text-start py-3 px-5'}>CPF</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            st.usuarios.map((us: any, key: number) => {
                                                                return (
                                                                    <tr >
                                                                        <td className="px-5">{us.nome}</td>
                                                                        <td className="px-5">{us.email}</td>
                                                                        <td className="px-5">{us.cpf}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                        </tbody>
                                                    </table>
                                                    :
                                                    <div className={'py-3'}>
                                                        <h1 className={'text-lg font-bold'}>Nenhum usuário cadastrado</h1>
                                                    </div>
                                                }

                                            </div>
                                            <div className={'w-[50%] py-3'}>
                                                <hr  />
                                            </div>
                                        </>
                                    );
                                })
                            }

                            {/*<div className={'py-4'}>
                                <h1 className={'text-lg'}>
                                    {'Loja: ' + storeData['0']['razao_social']} <br/>
                                    {'Responsavel: ' + storeData[0]['responsavel']}
                                </h1>
                                <h1 className={'text-lg'}>
                                    Usuários
                                </h1>
                            </div>
                            <div>
                                <table className="table-auto w-[50%]">
                                    <thead className="bg-light border-b">
                                    <tr className={'border-t'}>
                                        <th className={'text-start py-3 px-5'}>Nome</th>
                                        <th className={'text-start py-3 px-5'}>Email</th>
                                        <th className={'text-start py-3 px-5'}>CPF</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className="px-5">The Sliding Mr. Bones (Next Stop, Pottersville)</td>
                                        <td className="px-5">Malcolm Lockyer</td>
                                        <td className="px-5">1961</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>*/}
                        </div>
                        :
                        <div className={'py-5'}>
                            <h1 className={'text-lg font-bold'}>{clientData.razao_social}</h1>
                        </div>
                }
            </PageCard>
        </PageContainer>
    );
}