/* eslint-disable @typescript-eslint/no-explicit-any */
import {ForgotPassData, LoginData, Usuario} from '../types';
import api, { RequestError, RequestResponse } from './api';

export default  {
  async login(loginData: LoginData): Promise<RequestError | RequestResponse<{
    status: true;
    message: string;
    token: string;
    usuario: Usuario;
    menu: any;
  }>> {
    try {
      const { data } = await api.post('/auth/admin-login', loginData);
      return { data, isError: false };
    } catch (err: any) {
      return { 
        data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
        isError: true
      };
    }
  },

  async forgotPass(forgotPassData: ForgotPassData): Promise<RequestError | RequestResponse<{
    status: true;
    message: string;
  }>> {
    try {
      const { data } = await api.post('/resetar-senha', forgotPassData);
      return { data, isError: false };
    } catch (err: any) {
      console.log(err);
      return {
        data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
        isError: true
      };
    }
  }
};
