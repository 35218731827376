import React from 'react';
import { Navigate, Routes as ReactRouterDomRoutes, Route, RouteProps, useNavigate } from 'react-router-dom';

import appRoutes from './routes';
import Storage from '../helpers/Storage';

export interface AppRoute {
  path: string;
  requiresAuth: boolean;
  element: JSX.Element;
}

export function PrivateRoute({ children }: RouteProps) {
  const logged   = !!Storage.getApiToken();
  const userData = !!Storage.getUserData();

  return logged && userData ? (
    <>
      {children}
    </>
  ) : (
    <Navigate to="/entrar" />
  );
}

export function Routes() {
  return (
    <ReactRouterDomRoutes>
      {appRoutes.map(({ path, requiresAuth, element }) =>
        requiresAuth ? (
          <Route
            key={path}
            path={path}
            element={<PrivateRoute>{element}</PrivateRoute>}
          />
        ) : (
          <Route
            key={path}
            path={path}
            element={element}
          />
        ))}
    </ReactRouterDomRoutes>
  );
}
