import React from 'react';

import ListPageLayout from '../../components/layout/ListPageLayout';
import usuarioApi from '../../services/adminApi';

export default function UsersBase() {
  return <ListPageLayout
    menuTitle="Cadastro de Usuário"
    searchPlaceholder="Buscar usuários"
    creationEndpoint="/usuarios/cadastro"
    creationLabel="Cadastrar Usuário"
    loader={usuarioApi.all}
    columns={[
      { title: 'ID', field: 'id' },
      { title: 'Nome', field: 'nome' },
     /* { title: 'Perfil', field: 'telefone', columnType: 'phone' },*/
      { title: 'E-mail', field: 'email' },
      { title: 'Telefone', field: 'telefone', columnType: 'phone' },
      { title: 'Status', field: 'bloqueado', columnType: 'reversedStatus' },
    ]}
  />;
}
