import React from 'react';

import PageContainer from '../../../components/container/PageContainer';

export default function ExposureVision() {
  return (
    <PageContainer menuTitle="Exposição" pageTitle="Visão de Exposição">
      <h1>Visão de Exposição</h1>
    </PageContainer>
  );
}
