import React from 'react';
import { BiCheck } from 'react-icons/bi';

import Str from '../../../helpers/Str';

export interface CheckboxProps {
  name?: string;
  className?: string;
  label?: string;
  value?: any;
  onValue?: any;
  setValue?(value: any): void;
  setValueTarget?(value: any): void;
}

export default function Checkbox({
  name,
  className,
  label,
  value,
  onValue,
  setValue,
  setValueTarget
}: CheckboxProps) {
  return (
    <label className={Str.tw(
      'flex items-center gap-3 cursor-pointer',
      className,
    )}>
      <span className={Str.tw(
        'h-[15px] w-[15px] min-h-[15px] min-w-[15px] max-h-[15px] max-w-[15px] rounded-md flex items-center justify-center',
        'border hover:border-primary transition',
        value ? 'bg-primary border-slate-400' : 'bg-transparent border-secondary'
      )}>
        {value && <BiCheck className="fill-in-primary mb-[0.5px]" size={14} />}
      </span>
      <input
        hidden
        name={name}
        type="checkbox"
        value={onValue}
        checked={value}
        onChange={({ target }) => {
          setValue && setValue(target.checked);
          setValueTarget && setValueTarget(target);
        }}
      />
      {label}
    </label>
  );
}