import React from 'react';

export interface DashboardCardProps {
    title: string;
    value: string;
    collor?: string;
    icon?: any;
}

export default function DashboardCard(data: DashboardCardProps){
    return(
        <div className={`flex items-center justify-between p-5 bg-[#f6fafd] rounded shadow-sm`}>
            <div>
                <div className="text-md text-[#515151] ">{data.title}</div>
                <div className="flex items-center pt-1">
                    <div className="text-2xl font-medium text-indigo-400 ">{data.value}</div>
                </div>
            </div>
            <div className="text-gray-300">
                {data.icon}
            </div>
        </div>
    )
}