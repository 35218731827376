import { QueryParams, Produto } from '../types';
import api, {getAll, create, update, getOne, deleteOne, RequestError, RequestResponse} from './api';
import axios from "axios";
import Storage from "../helpers/Storage";

const endpoint = 'produtos';

export default {
  all: async (params?: QueryParams) => getAll<Produto>({ endpoint, params }),
  one: async (id: string) => getOne<Produto>({ endpoint, id }),
  destroy: async (id: string) => deleteOne({ endpoint, id }),
  create: async (formData: Partial<Produto>) => create<Produto>({ endpoint, formData }),
  update: async (id: string, formData: Partial<Produto>) => update<Produto>({ endpoint, formData, id }),
  async uploadFile (formData: any): Promise<RequestError | RequestResponse> {
    const api = axios.create({
      baseURL: process.env.REACT_APP_API,
      headers: {
        "access-control-allow-origin" : "*",
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    });
    api.defaults.headers.common['Authorization'] = 'Bearer ' + Storage.getApiToken();
    try {
      const { data } = await api.post(endpoint+'/upload-arquivo', formData);
      return { data, isError: false };
    } catch (err: any) {
      return {
        data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
        isError: true
      };
    }
  },
  async getAllWith(params?: QueryParams){
    try {
      const page    = params?.page    ? 'page='    + params?.page    : 'page=1';
      const perPage = params?.perPage ? '&perpage=' + params?.perPage : '';
      const search  = params?.search  ? '&search='  + params?.search  : '';
      const { data } = await api.get(`${endpoint}?${page}${perPage}${search}&relations=rDepartamento,rSecao`);
      return { data, isError: false };
    } catch (err: any) {
      console.log(err);
      return {
        data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
        isError: true
      };
    }
  },
  async getRevisao(params?: QueryParams){
    try {
      const page    = params?.page    ? 'page='    + params?.page    : 'page=1';
      const perPage = params?.perPage ? '&perpage=' + params?.perPage : '';
      const search  = params?.search  ? '&search='  + params?.search  : '';
      const { data } = await api.get(`${endpoint}?${page}${perPage}${search}&where=revisao,=,true&relations=rDepartamento,rSecao`);
      return { data, isError: false };
    } catch (err: any) {
      console.log(err);
      return {
        data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
        isError: true
      };
    }
  },
};
