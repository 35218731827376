import React, { createElement } from 'react';
import Str from '../../../helpers/Str';
import { IconBaseProps, IconType } from 'react-icons/lib';

export interface IconButtonProps {
  icon: IconType;
  className?: string;
  title?: string;
  onClick?: () => void;
}

export default function IconButton({
  icon,
  className,
  ...rest
}: IconButtonProps) {
  const MIcon = (props: IconBaseProps) => {
    return createElement(icon, props);
  };

  return (
    <button
      {...rest}
      className={Str.tw(className, 'p-3 rounded-full transition hover:bg-light-dark')}
    >
      <MIcon />
    </button>
  );
}