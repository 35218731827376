import cNames from 'classnames';
import { twMerge } from 'tailwind-merge';

const Str = {
  firstToUpperCase(str?: string) {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  },

  addCpfCnpjMask(str?: string) {
    if (!str) return '';
    str = Str.removeNonNumbers(str);
    if (str.length === 11) {// CPF
      return str.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (str.length === 14) {// CNPJ
      return str.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }
    return '';
  },

  addPhoneMask(str?: string, ddd?: string) {
    if (!str) return;
    str = Str.removeNonNumbers(str);
    ddd = ddd || '62';
    ddd = Str.removeNonNumbers(ddd);

    if (str.length === 8) {
      return str.replace(/(\d{4})(\d{4})/, `(${ddd}) 9 $1-$2`);
    }
    
    if (str.length === 9) {
      return str.replace(/(\d{1})(\d{4})(\d{4})/, `(${ddd}) $1 $2-$3`);
    }

    if (str.length === 10) {
      return str.replace(/(\d{2})(\d{4})(\d{4})/, '($1) 9 $2-$3');
    }

    if (str.length === 11) {
      return str.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $3-$4');
    }

    return '';
  }, 

  removeNonNumbers(str?: string) {
    if (!str) return '';
    return str.replace(/\D/g,'');
  },

  convertDateCreatedAt(date: any, hours?: boolean) {
    if (date) {
      if (hours){
        date = date.split("T")
        const finalDate = new Date(date[0].replace(/-/g, '/')).toLocaleDateString('pt-br')+' '+date[1]
        return finalDate.slice(0, 16);
      }
      date = date.split("T")[0]
      return new Date(date.replace(/-/g, '/')).toLocaleDateString('pt-br');
    }else{
      return date;
    }
  },

  /** 
   * Concatena classes para o Tailwind
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tw: (className?: string, ...args: any[]) => twMerge(className, cNames(args)),
};

export default Str;
