import React from 'react';

import { AppRoute } from '.';

import Login from '../pages/Auth/Login';
import StoreRegistration from '../pages/Registrations/StoreRegistration';
import SurveyType from '../pages/Registrations/SurveyType';
import UserRegistration from '../pages/Users/UserRegistration';
import UserProfile from '../pages/Users/UserProfile';
import UserList from '../pages/Users/index';
import ProductsBase from '../pages/Registrations/ProductsBase';
import Registrations from '../pages/Registrations';
import Dashboards from '../pages/Dashboards';
import ValidityVision from '../pages/Dashboards/ValidityVision';
import ExposureVision from '../pages/Dashboards/ExposureVision';
import NumbersEvolution from '../pages/Dashboards/NumbersEvolution';
import StoreProduct from '../pages/Registrations/StoreProduct';
import Locations from '../pages/Registrations/Locations';
import UserResetSenha from "../pages/Users/UserResetSenha";
import Client from "../pages/Registrations/Client";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import DashboardClient from "../pages/Dashboards/DashboardClient";
import ListDepartament from "../pages/Registrations/Department";
import FormDepartament from "../pages/Registrations/Department/Form";
import ListSection from "../pages/Registrations/Section";
import FormSection from "../pages/Registrations/Section/Form";

const appRoutes: AppRoute[] =  [
  {
    path: '/',
    requiresAuth: true,
    element: <Dashboards /> 
  },
  {
    path: '/dashboard/cliente',
    requiresAuth: true,
    element: <DashboardClient />
  },
  {
    path: '/dashboards/visao-validade',
    requiresAuth: true,
    element: <ValidityVision /> 
  },
  {
    path: '/dashboards/visao-exposicao',
    requiresAuth: true,
    element: <ExposureVision /> 
  },
  {
    path: '/dashboards/evolucao-numeros',
    requiresAuth: true,
    element: <NumbersEvolution /> 
  },
  {
    path: '/entrar',
    requiresAuth: false,
    element: <Login /> 
  },
  {
    path: '/recuperar/senha',
    requiresAuth: false,
    element: <ForgotPassword />
  },
  {
    path: '/cadastros/clientes',
    requiresAuth: true,
    element: <Registrations /> 
  },
  {
    path: '/cadastros/cadastro-clientes/:id',
    requiresAuth: true,
    element: <Client />
  },
  {
    path: '/cadastros/cadastro-clientes',
    requiresAuth: true,
    element: <Client />
  },
  {
    path: '/cadastros/base-produtos',
    requiresAuth: true,
    element: <ProductsBase /> 
  },
  {
    path: '/cadastros/base-produtos/cadastro',
    requiresAuth: true,
    element: <StoreProduct /> 
  },
  {
    path: '/cadastros/base-produtos/cadastro/:id',
    requiresAuth: true,
    element: <StoreProduct /> 
  },
  {
    path: '/cadastros/localizacoes',
    requiresAuth: true,
    element: <Locations /> 
  },
  {
    path: '/cadastros/tipo-pesquisa',
    requiresAuth: true,
    element: <SurveyType /> 
  },
  {
    path: '/usuarios/lista',
    requiresAuth: true,
    element: <UserList />
  },
  {
    path: '/usuarios/cadastro',
    requiresAuth: true,
    element: <UserRegistration /> 
  },
  {
    path: '/usuarios/cadastro/:id',
    requiresAuth: true,
    element: <UserRegistration />
  },
  {
    path: '/usuarios/perfil',
    requiresAuth: true,
    element: <UserProfile />
  },
  {
    path: '/admins/reset-senha/:hash',
    requiresAuth: false,
    element: <UserResetSenha />
  },
  {
    path: '/departamento/lista',
    requiresAuth: true,
    element: <ListDepartament />
  },
  {
    path: '/departamento/cadastro',
    requiresAuth: true,
    element: <FormDepartament />
  },
  {
    path: '/departamento/cadastro/:id',
    requiresAuth: true,
    element: <FormDepartament />
  },
  {
    path: '/secao/lista',
    requiresAuth: true,
    element: <ListSection />
  },
  {
    path: '/secao/cadastro',
    requiresAuth: true,
    element: <FormSection />
  },
  {
    path: '/secao/cadastro/:id',
    requiresAuth: true,
    element: <FormSection />
  },
  {
    path: '/*',
    requiresAuth: false,
    element: <h1>404</h1>
  }
];

export default appRoutes;
