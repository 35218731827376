import React, { useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';

import PageCard from '../../../components/card/PageCard';
import Input from '../../../components/input/Input';
import SearchButton from '../../../components/button/SearchButton';
import Button from '../../../components/button/Button'; 
import Form from '../../../components/form/Form';
import Str from "../../../helpers/Str";
import Alert from "../../../helpers/Alert";

export interface SearchPageCardProps {
  placeholder: string;
  left?: React.ReactNode;
  creationPath?: string;
  creationLabel?: string;
  fullWidthInput?: boolean;
  onSearch?: (q: string) => void;
  secondBtnfunction?: any;
  secondBtnText?: string;
}

export default function SearchPageCard({
  placeholder,
  creationPath,
  creationLabel,
  fullWidthInput,
  onSearch,
  secondBtnfunction,
  secondBtnText
}: SearchPageCardProps) {
  const navigate = useNavigate();
  const [q, setQ] = useState('');

    const hiddenFileInput = React.useRef(null);

    const handleClick = () => {
       // @ts-ignore
        hiddenFileInput?.current.click();
    };

  return (
    <PageCard innerClassName="flex items-center gap-7">
      {creationPath && <Button 
        className="min-w-[170px]"
        onClick={() => navigate(creationPath)}
      >
        {creationLabel}
      </Button>}
        {
            secondBtnfunction &&
            <>
            <label htmlFor="contained-button-file">
                <Button
                    className="min-w-[147px]"
                    onClick={() => handleClick()}
                >
                    { secondBtnText }
                </Button>
                <input type="file"
                       ref={hiddenFileInput}
                       onChange={secondBtnfunction}
                       style={{display:'none'}}
                />
            </label>
            <Link
                to={'/excel/baseProdutos.xlsx'}
                onClick={() => {Alert.error('Não altere o cabeçalho ou o tipo do arquivo!', 'Atenção!')}}
                target="_blank"
                download
                className={Str.tw(
                    'bg-light border rounded-md w-max min-w-[140px] p-3',
                    'transition hover:bg-light-dark active:border-primary',
                    'disabled:opacity-70 disabled:pointer-events-none',
                    'font-bold ',
                )}
            >
              Baixar modelo Excel
            </Link>
            </>

        }
      <Form className="flex items-center gap-2 w-full" onSubmit={() => onSearch && onSearch(q)}>
        <Input 
          hideLabel 
          placeholder={placeholder} 
          className={fullWidthInput ? '' : 'max-w-[340px]'}
          value={q}
          setValue={setQ}
        />
        <SearchButton type="submit" />
      </Form>
    </PageCard>
  );
}
