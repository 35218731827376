import React from 'react';

import Str from '../../../helpers/Str';

export interface PageCardProps {
  className?: string;
  removeContentPadding?: true;
  innerClassName?: string;
  headerClassName?: string;
  footerClassName?: string;
  children?: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
}

export default function PageCard({
  className,
  innerClassName,
  headerClassName,
  footerClassName,
  children,
  header,
  footer,
  removeContentPadding,
  ...rest
}: PageCardProps) {
  return (
    <div {...rest} className={Str.tw(
      className,
      'bg-background rounded-lg border'
    )}>
      {!!header && (
        <header className={Str.tw(headerClassName, 'p-5 border-b')}>
          {header}
        </header>
      )}
      <main className={Str.tw(innerClassName, removeContentPadding ? '' : 'p-5')}>
        {children}
      </main>
      {!!footer && (
        <footer className={Str.tw(footerClassName, 'p-5 border-t')}>
          {footer}
        </footer>
      )}
    </div>
  );
}
