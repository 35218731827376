import React, {useEffect, useState} from 'react';

import produtoApi from '../../../services/produtoApi';
import { Produto } from '../../../types';

import RegisterPageLayout from '../../../components/layout/RegisterPageLayout';
import Input from '../../../components/input/Input';
import Select from '../../../components/select/Select';
import Alert from "../../../helpers/Alert";
import sectionApi from "../../../services/sectionApi";
import departamentoApi from "../../../services/departamentoApi";
import {useParams} from "react-router-dom";

export default function StoreProduct() {
  const [sectionOptions, setSectionOptions] = useState([]);
  const [departamentOptions, setDepartamentOptions] = useState([]);
  const [formDefalt, setFormDefalt] = useState<any>([]);

    async function loadData() {
        Alert.await(`Carregando dados...`);
        const { data, isError } = await sectionApi.listAll();
        const secOpt = data.map((dt: any) => {
          return { label: dt.nome, key:dt.id  }
        });
        setSectionOptions(secOpt);

        const dataDepartament = await departamentoApi.listAll();
        const depOpt = dataDepartament.data.map((dt: any) => {
          return { label: dt.nome, key:dt.id  }
        });
        setDepartamentOptions(depOpt);

        Alert.close();
    }

    useEffect(() => {
    loadData();
    }, []);


  return (
      <>
          <RegisterPageLayout<Produto>
              resourceName="produto"
              menuTitle="Base de Produtos"
              finishPath="/cadastros/base-produtos"
              returnPath="/cadastros/base-produtos"
              loader={produtoApi.one}
              createHandler={produtoApi.create}
              updateHandler={produtoApi.update}
              destroyHandler={produtoApi.destroy}
              formContent={(form, handleChange, id, load) => {
                  const ajusteValor = (value: any) => {
                      const array = value.split(".");
                      if (array[0].length < 2){
                          return '00000'+array[1]
                      }
                      return value
                  }
                  return (
                      <>
                          {
                              load ? "" :
                                  <>
                                      <div className="grid grid-cols-10 gap-5">
                                          <Input
                                              label="Descrição do Produto"
                                              className="col-span-5"
                                              value={form.descricao}
                                              setValue={v => handleChange(v, 'descricao')}
                                          />

                                          <Input
                                              label="EAN"
                                              type="number"
                                              min={0}
                                              className="col-span-1"
                                              value={String(form.ean)}
                                              setValue={v => handleChange(v, 'ean')}
                                          />

                                          <Input
                                              label="DUN"
                                              type="number"
                                              min={0}
                                              className="col-span-1"
                                              value={form.dun}
                                              setValue={v => handleChange(v, 'dun')}
                                          />

                                          {
                                              id ?
                                                  <Input
                                                      label="Código Inovatta"
                                                      type="number"
                                                      min={0}
                                                      disabled={true}
                                                      className="col-span-1"
                                                      value={form.cod_inovatta}
                                                      setValue={v => handleChange(v, 'cod_inovatta')}
                                                  />
                                                  :
                                                  ''
                                          }

                                          <Input
                                              label="Código Interno"
                                              type="number"
                                              min={0}
                                              className="col-span-1"
                                              value={form.cod_interno}
                                              setValue={v => handleChange(v, 'cod_interno')}
                                          />

                                          <Input
                                              label="Código Fábrica"
                                              type="number"
                                              min={0}
                                              className="col-span-1"
                                              value={form.cod_fabrica}
                                              setValue={v => handleChange(v, 'cod_fabrica')}
                                          />

                                          <Input
                                              label="Embalagem"
                                              className="col-span-6"
                                              value={form.embalagem}
                                              setValue={v => handleChange(v, 'embalagem')}
                                          />

                                          <Input
                                              label="Unidade de Medida"
                                              className="col-span-4"
                                              value={form.UN}
                                              setValue={v => handleChange(v, 'UN')}
                                          />

                                      </div>
                                      <div className="grid grid-cols-12 gap-5 mt-5">
                                          <Input
                                              label="Altura (cm)"
                                              mask="cm"
                                              className="col-span-2"
                                              value={form.altura}
                                              setValue={v => handleChange(v, 'altura')}
                                          />

                                          <Input
                                              label="Largura (cm)"
                                              mask="cm"
                                              className="col-span-2"
                                              value={form.largura}
                                              setValue={v => handleChange(v, 'largura')}
                                          />

                                          <Input
                                              label="Comprimento (cm)"
                                              mask="cm"
                                              className="col-span-2"
                                              value={form.comprimento}
                                              setValue={v => handleChange(v, 'comprimento')}
                                          />

                                          <Input
                                              label="Peso Líquido (kg)"
                                              mask="kg"
                                              className="col-span-2"
                                              value={ajusteValor(String(form.peso_liquido))}
                                              setValue={v => handleChange(v, 'peso_liquido')}
                                          />

                                          <Input
                                              label="Peso Bruto (kg)"
                                              mask="kg"
                                              className="col-span-2"
                                              value={ajusteValor(String(form.peso_bruto))}
                                              setValue={v => handleChange(v, 'peso_bruto')}
                                          />

                                          <Select
                                              label="Possui Validade?"
                                              className="col-span-2"
                                              value={form.validade}
                                              setValue={v => handleChange(v, 'validade')}
                                              items={[
                                                  { key: true, label: 'Sim'},
                                                  { key: false, label: 'Não'},
                                              ]}
                                          />

                                          {/*<Input
                                              label="Departamento"
                                              className="col-span-7"
                                              value={form.departamento}
                                              setValue={v => handleChange(v, 'departamento')}
                                          />

                                          <Input
                                              label="Seção"
                                              className="col-span-5"
                                              value={form.secao}
                                              setValue={v => handleChange(v, 'secao')}
                                          />*/}
                                      </div>
                                      <div className="grid grid-cols-12 gap-5 mt-5">
                                          <Select
                                              label="Departamento"
                                              className="col-span-6"
                                              value={form.departamento_id}
                                              setValue={v => handleChange(v, 'departamento_id')}
                                              items={departamentOptions}
                                          />
                                          <Select
                                              label="Seção"
                                              className="col-span-6"
                                              value={form.secao_id}
                                              setValue={v => handleChange(v, 'secao_id')}
                                              items={sectionOptions}
                                          />
                                      </div>
                                  </>
                          }
                      </>
                  )
              }}
          />
      </>
  )
}
