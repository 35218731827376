import React from 'react';

import ListPageLayout from '../../../components/layout/ListPageLayout';
import sectionApi from "../../../services/sectionApi";

export default function ListSection() {
    return <ListPageLayout
        menuTitle="Cadastro de Seção"
        searchPlaceholder="Buscar Seção"
        creationEndpoint="/secao/cadastro"
        creationLabel="Cadastro de Seção"
        loader={sectionApi.all}
        columns={[
            { title: 'ID', field: 'id' },
            { title: 'Nome', field: 'nome' },
            { title: 'Status', field: 'status', columnType: 'status' },
        ]}
    />;
}