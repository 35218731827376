import React from 'react';

import Str from '../../../helpers/Str';

export interface StatusBadgeProps {
  status: boolean;
  options?: string[];
  className?: string;
}

export default function StatusBadge({
  status,
  options,
  className
}: StatusBadgeProps) {
  function handleStatusClasses() {
    switch (status) {
    case false:
      return 'bg-red-100 text-red-700';
    case true:
      return 'bg-green-100 text-green-700';
    default:
      return 'bg-light-600';
    }
  }

  return (
    <span className={Str.tw(
      'rounded px-3 font-medium tracking-wide pb-[1px]',
      handleStatusClasses(),
      className
    )}>
      <StatusText status={status} options={options} />
    </span>
  );
}

function StatusText(props: { status: boolean, options?: string[] }) {
  switch (props.status) {
  case false:
    return <>{props.options ? props.options[1] : 'Inativo'}</>;
  case true:
    return <>{props.options ? props.options[0] : 'Ativo'}</>;
  default:
    return <></>;
  }
}

