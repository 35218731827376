import React from 'react';

import ListPageLayout from '../../../components/layout/ListPageLayout';
import departamentoApi from "../../../services/departamentoApi";

export default function ListDepartament() {
    return <ListPageLayout
        menuTitle="Cadastro de Departamento"
        searchPlaceholder="Buscar Departamento"
        creationEndpoint="/departamento/cadastro"
        creationLabel="Cadastrar Departamento"
        loader={departamentoApi.all}
        columns={[
            { title: 'ID', field: 'id' },
            { title: 'Nome', field: 'nome' },
            { title: 'Status', field: 'status', columnType: 'status' },
        ]}
    />;
}