import { QueryParams, Role } from '../types';
import {getAll, create, update, getOne, deleteOne, listAll} from './api';

const endpoint = 'role';

export default {
    all: async (params?: QueryParams) => getAll<Role>({ endpoint, params }),
    listAll: async () => listAll<Role>({ endpoint }),
    one: async (id: string) => getOne<Role>({ endpoint, id }),
    destroy: async (id: string) => deleteOne({ endpoint, id }),
    create: async (formData: Partial<Role>) => create<Role>({ endpoint, formData }),
    update: async (id: string, formData: Partial<Role>) => update<Role>({ endpoint, formData, id }),
};
