import React, { useState } from 'react';

import Str from '../../../helpers/Str';

export interface PaginationProps {
  className?: string;
  currentPage: number;
  lastPage: number;
  onPageChange?: (page: number) => void;
}

export default function Pagination({
  className,
  currentPage,
  lastPage,
  onPageChange,
}: PaginationProps) {
  const [shownCurrentPage, setShownCurrentPage] = useState(currentPage);

  function handlePageChange(p: number) {
    setShownCurrentPage(p);
    if (onPageChange) onPageChange(p);
  }
  
  return (
    <div className={Str.tw(className, 'border rounded-md')}>
      <PaginationButton 
        first 
        disabled={shownCurrentPage < 2}
        onClick={() => handlePageChange(shownCurrentPage - 1)}
      >
        <span className='hidden lg:block'>Anterior</span>
        <span className='lg:hidden'>{'<'}</span>
      </PaginationButton>
      {(shownCurrentPage - 3) > 2 && <PaginationButton onClick={() => handlePageChange(1)}>{1}</PaginationButton>}
      {(shownCurrentPage - 3) > 3 && <PaginationButton disabled>...</PaginationButton>}
      {(shownCurrentPage - 2) > 1 && <PaginationButton onClick={() => handlePageChange(shownCurrentPage - 2)}>{shownCurrentPage - 2}</PaginationButton>}
      {(shownCurrentPage - 1) > 0 && <PaginationButton onClick={() => handlePageChange(shownCurrentPage - 1)}>{shownCurrentPage - 1}</PaginationButton>}
      <PaginationButton disabled current>{shownCurrentPage}</PaginationButton>
      {(lastPage - shownCurrentPage) > 1  &&<PaginationButton onClick={() => handlePageChange(shownCurrentPage + 1)}>{shownCurrentPage + 1}</PaginationButton>}
      {(lastPage - shownCurrentPage) > 2  && <PaginationButton onClick={() => handlePageChange(shownCurrentPage + 2)}>{shownCurrentPage + 2}</PaginationButton>}
      {(lastPage - shownCurrentPage) > 3  && <PaginationButton disabled>...</PaginationButton>}
      {shownCurrentPage != lastPage && <PaginationButton onClick={() => handlePageChange(lastPage)}>{lastPage}</PaginationButton>}
      <PaginationButton 
        last 
        disabled={shownCurrentPage == lastPage} 
        onClick={() => handlePageChange(shownCurrentPage + 1)}
      >
        <span className='hidden lg:block'>Próxima</span>
        <span className='lg:hidden'>{'>'}</span>
      </PaginationButton>
    </div>
  );
}

interface PaginationButtonProps {
  children: any;
  disabled?: boolean;
  current?: boolean;
  first?: boolean;
  last?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (event: any) => void;
}

function PaginationButton({
  children,
  disabled,
  current,
  first,
  last,
  onClick
}: PaginationButtonProps) {
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={onClick}
      className={Str.tw(
        'border-l p-2 px-4 bg-light transition hover:bg-primary hover:text-in-primary disabled:pointer-events-none disabled:bg-background',
        current ? 'font-bold bg-primary' : '',
        first ? 'rounded-tl-md rounded-bl-md' : '',
        last ? 'rounded-tr-md rounded-br-md' : '',
      )}
    >
      {children}
    </button>
  );
}
