import { QueryParams, Loja } from '../types';
import { getAll, create, update, getOne, deleteOne } from './api';

const endpoint = 'lojas';

export default {
  all: async (params?: QueryParams) => getAll<Loja>({ endpoint, params }),
  one: async (id: string) => getOne<Loja>({ endpoint, id }),
  destroy: async (id: string) => deleteOne({ endpoint, id }),
  create: async (formData: Partial<Loja>) => create<Loja>({ endpoint, formData }),
  update: async (id: string, formData: Partial<Loja>) => update<Loja>({ endpoint, formData, id }),
};
