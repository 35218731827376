import React from 'react';

import PageContainer from '../../../components/container/PageContainer';

export default function ValidityVision() {
  return (
    <PageContainer menuTitle="Validade" pageTitle="Visão de Validade">
      <h1>Visão de Validade</h1>
    </PageContainer>
  );
}
