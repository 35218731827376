import React from 'react';

import { TipoLeitura } from '../../../types';

import SearchPageCard from '../../../components/card/SearchPageCard';
import PageContainer from '../../../components/container/PageContainer';
import Table from '../../../components/table/Table';
import PageCard from '../../../components/card/PageCard';
import Title from '../../../components/text/Title';
import Button from '../../../components/button/Button';
import Checkbox from '../../../components/checkbox/Checkbox';
import useTable from '../../../hooks/useTable';
import tipoLeituraApi from '../../../services/tipoLeituraApi';

export default function SurveyType() {
  const { tableProps, onSearch } = useTable<TipoLeitura>({
    dataLoader: async (params) => {
      const { data, isError } = await tipoLeituraApi.all(params);
      return isError ? false : data;
    }
  });

  return (
    <PageContainer menuTitle="Tipos de Pesquisa">
      <div className="grid grid-cols-3 gap-2">
        <div className="flex flex-col gap-2 col-span-1">
          <SearchPageCard 
            fullWidthInput
            onSearch={onSearch}
            placeholder="Buscar tipos de pesquisa"
          />
          <Table {...tableProps} columns={[
            { title: 'Perfil', field: 'descricao' },
            { title: 'Status', field: 'bloqueado', columnType: 'reversedStatus' },
          ]} />
        </div>

        <PageCard
          className="col-span-2" 
          header={<Title color="primary">Cadastro de Tipo de Pesquisa</Title>}
          footer={<div className="flex items-center gap-5">
            <Button>
            Salvar
            </Button>
          </div>}
        >
          Campos disponíveis para pesquisa
          <div className="border rounded-md mt-2 p-5 grid gap-5">
            <Checkbox label="Descrição" />
            <Checkbox label="Tipo" />
            <Checkbox label="Quantidade" />
            <Checkbox label="Data de Validade" />
            <Checkbox value={true} label="Preço Varejo" />
            <Checkbox value={true} label="Preço Atacado" />
            <Checkbox label="Número do Lote" />
            <Checkbox label="Fotos" />
          </div>
        </PageCard>
      </div>
    </PageContainer>
  );
}
