import React from 'react';

import PageContainer from '../../../components/container/PageContainer';

export default function NumbersEvolution() {
  return (
    <PageContainer menuTitle="Evolução dos Números">
      <h1>Evolução dos Números</h1>
    </PageContainer>
  );
}
