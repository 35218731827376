import React, {useEffect, useState} from 'react';

import PageContainer from '../../components/container/PageContainer';
import Title from "../../components/text/Title";
import IconButton from "../../components/button/IconButton";
import {BiArrowBack, BiEdit, BiStore} from "react-icons/bi";
import Button from "../../components/button/Button";
import PageCard from "../../components/card/PageCard";
import DashboardCard from "../../components/card/DashboardCard";
import {FaClipboardCheck, FaUser} from 'react-icons/fa';
import {RiAdminFill} from 'react-icons/ri';
import clientesApi from "../../services/clientApi";
import dashboardApi from "../../services/dashboardApi";
import Alert from "../../helpers/Alert";
import useTable from "../../hooks/useTable";
import Table from "../../components/table/Table";
import produtoApi from "../../services/produtoApi";
import Modal from "../../components/modal/Modal";
import useModal from "../../hooks/useModal";
import Input from "../../components/input/Input";
import Select from "../../components/select/Select";
import sectionApi from "../../services/sectionApi";
import departamentoApi from "../../services/departamentoApi";
import Form from "../../components/form/Form";


export default function Dashboards() {
    const [client, setClient] = useState('0');
    const [store, setStore] = useState('0');
    const [userAdmin, setUserAdmin] = useState('0');
    const [userClient, setUserClient] = useState('0');
    const [product, setProduct]: any = useState({});
    const [sectionOptions, setSectionOptions] = useState([]);
    const [departamentOptions, setDepartamentOptions] = useState([]);

    async function loadData() {
        Alert.await(`Carregando dados...`);
        const { data, isError } = await dashboardApi.dashboardData();
        if (isError) return alert(data.message || 'Falha ao carregar dados.');
        setClient(data.clientes);
        setStore(data.lojas);
        setUserAdmin(data.admins);
        setUserClient(data.usuarios);

        const [dataSection, dataDepartament] = await Promise.all([sectionApi.listAll(), departamentoApi.listAll()])

        const secOpt = dataSection.data.map((dt: any) => {
            return { label: dt.nome, key:dt.id  }
        });
        setSectionOptions(secOpt);

        const depOpt = dataDepartament.data.map((dt: any) => {
            return { label: dt.nome, key:dt.id  }
        });
        setDepartamentOptions(depOpt);

        Alert.close();
    }
    const { modalProps, open, close } = useModal();
    useEffect(() => {
        loadData();
    }, []);
    const { tableProps, onSearch } = useTable({
        dataLoader: async (params) => {
            const { data, isError } = await produtoApi.getRevisao(params);
            return isError ? false : data;
        }
    });
    const columns: any = [
        { title: 'EAN', field: 'ean' },
        { title: 'Descrição', field: 'descricao' },
    ]
    const onClickEditProd = (data: any) => {
        setProduct(data);
        open();
    }
    const handleChange = (value: any, input: string) => {
        setProduct({...product, [input]: value});
    };
    const ajusteValor = (value: any) => {
        const array = value.split(".");
        if (array[0].length < 2){
            return '00000'+array[1]
        }
        return value
    }
    async function handleSubmit() {
        Alert.await(`Salvando dados...`);
        const { data, isError } = await produtoApi.update(product.id, product)
        if (isError){
            if (typeof data === 'object') {
                Object.keys(data).forEach(function(key, index) {
                    Alert.error(data[key][0]);
                });
                return;
            }

            return Alert.error(data.message? data.message : data);
        }
        if (typeof data === "string"){
            Alert.error(data);
        }
        setProduct({})
        close()
        onSearch('');
        Alert.close();
    }
    return (
        <>
            <PageContainer menuTitle="Dashboard">
                <PageCard
                    header={
                        <div className="flex items-center justify-between">
                            <Title color="primary">Dashboard</Title>
                        </div>
                    }
                >
                    <div className="flex items-center">
                        <div className="container max-w-6xl px-5 mx-auto my-28">
                            <div className="grid gap-7 sm:grid-cols-2 lg:grid-cols-4">
                                <DashboardCard
                                    title={'Clientes'}
                                    value={client.toString()}
                                    icon={
                                        <FaClipboardCheck color={'#515151'} size={'25px'}/>
                                    }
                                />
                                <DashboardCard
                                    title={'Lojas'}
                                    value={store.toString()}
                                    icon={
                                        <BiStore color={'#515151'} size={'25px'}/>
                                    }
                                />
                                <DashboardCard
                                    title={'Usuários Admin'}
                                    value={userAdmin.toString()}
                                    icon={
                                        <RiAdminFill color={'#515151'} size={'25px'}/>
                                    }
                                />
                                <DashboardCard
                                    title={'Usuários Clientes'}
                                    value={userClient.toString()}
                                    icon={
                                        <FaUser color={'#515151'} size={'25px'}/>
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={'py-5 font-bold text-[20px]'}>
                            Produtos para revisão
                        </div>
                        <Table {...tableProps} columns={[
                            {
                                title: 'Editar',
                                render: (item) => <IconButton icon={BiEdit} onClick={() => onClickEditProd((item as any))} />
                            },
                            ...columns,
                        ]} />
                    </div>
                </PageCard>
            </PageContainer>
            <Modal  title="Produtos para revisão" {...modalProps}>
                <Form onSubmit={() => handleSubmit()}>
                    <div className={'p-[20px]'}>
                        <div className="grid grid-cols-10 gap-5">
                            <Input
                                label="Descrição do Produto"
                                className="col-span-5"
                                value={product.descricao}
                                setValue={v => handleChange(v, 'descricao')}
                            />

                            <Input
                                label="EAN"
                                type="number"
                                min={0}
                                className="col-span-1"
                                value={String(product.ean)}
                                setValue={v => handleChange(v, 'ean')}
                            />

                            <Input
                                label="DUN"
                                type="number"
                                min={0}
                                className="col-span-1"
                                value={product.dun}
                                setValue={v => handleChange(v, 'dun')}
                            />

                            <Input
                                label="Código Inovatta"
                                type="number"
                                min={0}
                                disabled={true}
                                className="col-span-1"
                                value={product.cod_inovatta}
                                setValue={v => handleChange(v, 'cod_inovatta')}
                            />

                            <Input
                                label="Código Interno"
                                type="number"
                                min={0}
                                className="col-span-1"
                                value={product.cod_interno}
                                setValue={v => handleChange(v, 'cod_interno')}
                            />

                            <Input
                                label="Código Fábrica"
                                type="number"
                                min={0}
                                className="col-span-1"
                                value={product.cod_fabrica}
                                setValue={v => handleChange(v, 'cod_fabrica')}
                            />

                            <Input
                                label="Embalagem"
                                className="col-span-6"
                                value={product.embalagem}
                                setValue={v => handleChange(v, 'embalagem')}
                            />

                            <Input
                                label="Unidade de Medida"
                                className="col-span-4"
                                value={product.UN}
                                setValue={v => handleChange(v, 'UN')}
                            />

                        </div>
                        <div className="grid grid-cols-12 gap-5 mt-5">
                            <Input
                                label="Altura (cm)"
                                mask="cm"
                                className="col-span-2"
                                value={product.altura}
                                setValue={v => handleChange(v, 'altura')}
                            />

                            <Input
                                label="Largura (cm)"
                                mask="cm"
                                className="col-span-2"
                                value={product.largura}
                                setValue={v => handleChange(v, 'largura')}
                            />

                            <Input
                                label="Comprimento (cm)"
                                mask="cm"
                                className="col-span-2"
                                value={product.comprimento}
                                setValue={v => handleChange(v, 'comprimento')}
                            />

                            <Input
                                label="Peso Líquido (kg)"
                                mask="kg"
                                className="col-span-2"
                                value={ajusteValor(String(product.peso_liquido))}
                                setValue={v => handleChange(v, 'peso_liquido')}
                            />

                            <Input
                                label="Peso Bruto (kg)"
                                mask="kg"
                                className="col-span-2"
                                value={ajusteValor(String(product.peso_bruto))}
                                setValue={v => handleChange(v, 'peso_bruto')}
                            />

                            <Select
                                label="Possui Validade?"
                                className="col-span-2"
                                value={product.validade}
                                setValue={v => handleChange(v, 'validade')}
                                items={[
                                    { key: true, label: 'Sim'},
                                    { key: false, label: 'Não'},
                                ]}
                            />
                        </div>
                        <div className="grid grid-cols-12 gap-5 mt-5">
                            <Select
                                label="Departamento"
                                className="col-span-6"
                                value={product.departamento_id}
                                setValue={v => handleChange(v, 'departamento_id')}
                                items={departamentOptions}
                            />
                            <Select
                                label="Seção"
                                className="col-span-6"
                                value={product.secao_id}
                                setValue={v => handleChange(v, 'secao_id')}
                                items={sectionOptions}
                            />
                        </div>
                    </div>
                    <div className={'p-[20px] flex justify-end'}>
                        <Button type='submit' >
                            Salvar
                        </Button>
                    </div>
                </Form>
            </Modal>
        </>
    );
}
