import React, {useState} from 'react';

import clientesApi from '../../services/clientApi';

import ListPageLayout from '../../components/layout/ListPageLayout';
import Modal from "../../components/modal/Modal";
import useModal from "../../hooks/useModal";
import Str from "../../helpers/Str";
export default function ProductsBase() {
    const { modalProps, open, close } = useModal();
    const [listLogs, setListLogs] = useState([]);
    const viewLog = (data: any) => {
        setListLogs(data.logins)
        open()
    }
  return (
      <>
          <ListPageLayout
              menuTitle="Cadastro de Clientes"
              searchPlaceholder="Buscar cadastros"
              creationEndpoint="/cadastros/cadastro-clientes"
              creationLabel="Cadastrar Cliente"
              loader={clientesApi.all}
              relations={'logins'}
              onClick={viewLog}
              columns={[
                  { title: 'Razão Social', field: 'razao_social' },
                  { title: 'CNPJ', field: 'cnpj', columnType: 'CNPJ' },
                  { title: 'Nome Fantasia', field: 'nome_fantasia' },
                  { title: 'Responsável', field: 'responsavel' },
                  { title: 'Telefone', field: 'telefone_principal', columnType: 'phone' },
                  { title: 'Latitude', field: 'latitude' },
                  { title: 'Longitude', field: 'longitude' },
                  { title: 'Status', field: 'bloqueado', columnType: 'reversedStatus' },
              ]}
          />

          <Modal  title="Logs de login dos usuários" {...modalProps}>
              {
                  listLogs.length ?
                      <div className={'flex-row'}>
                          <div className={'pb-5 w-[100%]'}>
                              <table className="table-auto w-[100%]">
                                  <thead className="bg-light border-b">
                                  <tr className={'border-t'}>
                                      <th className={'text-start py-3 px-5'}>Usuário</th>
                                      <th className={'text-start py-3 px-5'}>Data de acesso</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  {
                                      listLogs.map((list: any) => {
                                            return(
                                                <tr>
                                                    <td className="px-5">{list.usuario.nome}</td>
                                                    <td className="px-5">{Str.convertDateCreatedAt(list.created_at, true)}</td>
                                                </tr>
                                            )
                                      })
                                  }
                                  </tbody>
                              </table>
                          </div>
                      </div>
                      :
                      <div>
                          Nenhum registro encontrado!
                      </div>
              }

          </Modal>

      </>
  )
}
