import React from 'react';

import produtoApi from '../../../services/produtoApi';

import ListPageLayout from '../../../components/layout/ListPageLayout';
import Alert from "../../../helpers/Alert";

export default function ProductsBase() {
    const updatePlanilha = async (e: any) => {
        Alert.await('Registrando produtos');
        const form = new FormData();
        form.append('file', e.target.files[0]);
        const {data, isError} = await produtoApi.uploadFile(form);
        Alert.close();
        if (isError) {
            let mensagem = "Algo deu errado";
            if (data.message){
                const dt = data.message.split(' ');
                const fields = dt['1'].split('.');
                mensagem = fields['1'] ? 'Campo '+fields['1']+' e obrigatório' : data;
            }
            return Alert.error(mensagem);
        }
        Alert.success(data);
    }

  return <ListPageLayout 
    menuTitle="Base de Produtos"
    searchPlaceholder="Buscar Produtos"
    creationEndpoint="/cadastros/base-produtos/cadastro"
    creationLabel="Cadastrar Produto"
    loader={produtoApi.getAllWith}
    secondBtnfunction={updatePlanilha}
    secondBtnText={'Importar planilha'}
    columns={[
      { title: 'Descrição', field: 'descricao' },
      { title: 'Marca', field: 'marca' },
      { title: 'Departamento', field: 'r_departamento.nome' },
      { title: 'Seção', field: 'r_secao.nome' },
      { title: 'Possui Validade?', field: 'validade', columnType: 'YesOrNo' },
    ]}
  />;
}
